/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import Dashboard from './views/Dashboard/Dashboard';
import Profile from './views/Profile/Profile';
import MySchedule from './views/MySchedule/MySchedule';
import ScheduleMatch from './views/ScheduleMatch/ScheduleMatch';
import TimeClock from './views/TimeClock/TimeClock';
import DaysOff from './views/DaysOff/DaysOff';
import HumanResources from './views/HumanResources/HumanResources';
import MyCompany from './views/MyCompany/MyCompany';
import Reports from './views/Reports/Reports';
import PasswordReset from './views/PasswordReset';
import { Switch, Route } from 'react-router-dom';
import ErrorPage from './views/ErrorPage/ErrorPage';
import Payroll from './views/Payroll/Payroll';
import MyDocuments from './views/MyDocuments/MyDocuments';
import MyMessages from './views/MyMessages/MyMessages';
import PCAT from './views/PCAT/PCAT';
import BudgetTool from './views/Budget/BudgetTool';
import Onboarding from './views/Onboarding';
import Login from './views/Login/Login';
import Admin from './views/Admin/Admin';
import Mail from './views/Mail';
import Cookies from 'universal-cookie';
import RecruitingSignUp from './views/RecruitingSignUp/RecruitingSignUp';
import DebugView from './views/DebugView';
import ApiManager from './ApiManager.tsx';
import Bookkeeping from './views/Bookkeeping/Bookkeeping.js';
import QuickBooksAuthentication from './views/Bookkeeping/QuickBooksAuthentication.js';


export default function Router({auth, permissions, handleCheckAuthentication}) {
    const hrPermissions = permissions.hr_profile == '1' || permissions.hr_schedule == '1' || permissions.hr_timesheet == '1' || permissions.hr_pay == '1' || permissions.hr_bwc == '1' || permissions.hr_documents == '1' || permissions.hr_permissions == '1';
    const userData = new Cookies().get('userData');

    return ( auth?.companyUserIdentifier ? 
        <Switch>
            <Route path='/profile' component={Profile}/>
            { permissions.timeClock && <Route path='/time-clock' component={TimeClock}/>}
            <Route path='/my-schedule' component={MySchedule}/>
            { permissions.daysOff === '1' && <Route path='/days-off' component={DaysOff}/>}
            <Route path='/my-documents' component={MyDocuments}/>
            <Route path='/my-messages' component={MyMessages}/>
            
            { permissions.myCompany === '1' && <Route path='/my-company' component={MyCompany}/>}
            { permissions.scheduleMatch === '1' && <Route path='/schedule-match' component={ScheduleMatch}/>}
            { permissions.payroll === '1' && <Route path='/payroll' component={Payroll}/>}
            { permissions.reports === '1' && <Route path='/reports' component={Reports}/>}
            { hrPermissions && <Route path='/human-resources' render={routeProps => (<HumanResources {...routeProps}/>)}/>}
            { permissions.payroll === '1' && <Route path='/pcat' component={PCAT}/> }
            { permissions.payroll === '1' && <Route path='/budget' component={BudgetTool}/>}
            <Route path='/bookkeeping' component={Bookkeeping}/>
            <Route path='/quickbooks-authentication' component={QuickBooksAuthentication}/>
            { ApiManager.DEV_MODE && 
                <Route path='/debug' component={DebugView}/>
            }

            <Route path='/onboarding' component={Onboarding}/>
            <Route path='/mail/:token' component={Mail}/>
            
            <Route exact path='/' component={Dashboard}/>
            <Route path='/' component={ErrorPage}/>
        </Switch>
        : auth?.adminIdentifier ? 
            <Switch>
                <Route path='/' render={() => <Admin handleCheckAuthentication={handleCheckAuthentication}/>}/>
            </Switch>
        :
            <Switch>
                <Route path='/reset-password/:passwordResetToken' component={PasswordReset}/>
                <Route path='/onboarding' component={Onboarding}/>
                <Route path='/mail/:token' component={Mail}/>
                <Route path='/' render={() => <Login handleCheckAuthentication={handleCheckAuthentication}/>}/>
            </Switch>
    )
}


