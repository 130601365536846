/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Dropdown, Modal, Row } from 'react-bootstrap';
import OfferLetterEditor from '../../../components/OfferLetterEditor';
import CustomCheck from '../../../components/CustomCheck';
import { DirectDepositDocuments, OnboardingDocuments, OnboardingDocumentsShort, TaxDocumentsShort, addressToString, availabilityToBinaryString, availabilityToString, defaultOfferLetter, defaultOfferLetterTitles, employeeTypeDictionary, formatPhoneNumber, formatTimeNew, parseAvailability, payTypeDictionary, stateTaxDocuments, taxDocumentDictionary, usdFormatter, validateDecimalFixed, validateInteger } from '../../../tools';
import SwitchControl from '../../../components/SwitchControl';
import RadioControl from '../../../components/RadioControl';
import { ValidationGroup, validateExistence } from '../../../validation';
import CustomControl from '../../../components/CustomControl';
import CustomButton from '../../../components/CustomButton';
import moment from 'moment';
import PageSpinner from '../../../components/PageSpinner';
import { ApiRequest } from '../../../ApiManager.tsx';

export default function DocumentRequestModal({companyUserIdentifier, hideModal, defaultAllDocumentsOn}) {
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [signers, setSigners] = useState([]);
    const [employee, setEmployee] = useState(undefined);
    const [company, setCompany] = useState(undefined);
    const [csa, setCsa] = useState(undefined);
    const [showOfferLetter, setShowOfferLetter] = useState(false);
    const [selectedDocuments, setSelectedDocuments] = useState(defaultAllDocumentsOn ? ['w4', 'i9', 'dd', 'f8850'] : []);
    const [selectedDirectDepositDocument, setSelectedDirectDepositDocument] = useState(defaultAllDocumentsOn ? 'dd' : null);
    const [selectedTaxDocument, setSelectedTaxDocument] = useState(undefined);
    const [includeEContacts, setIncludeEContacts] = useState(defaultAllDocumentsOn ?? false);
    const [includeOfferLetter, setIncludeOfferLetter] = useState(defaultAllDocumentsOn ?? false);
    const [offerLetters, setOfferLetters] = useState([]);
    const [offerLetter, setOfferLetter] = useState(defaultOfferLetter);
    const [selectedOfferLetter, setSelectedOfferLetter] = useState(-1);
    const [startDate, setStartDate] = useState(''); //W4, Offer Letter
    const [supervisorName, setSupervisorName] = useState(''); //Offer Letter
    const [startTime, setStartTime] = useState('08:00'); //Offer Letter
    const [dailyStartTime, setDailyStartTime] = useState('08:00'); //Offer Letter
    const [selectedAo, setSelectedAo] = useState(undefined); //Offer Letter
    const [employerSigner, setEmployerSigner] = useState(undefined); //I9, some Tax Documents
    const offerLetterElements = offerLetters.map((ol) => {
        return (
            <Dropdown.Item key={ol.uid} onClick={() => {setOfferLetter(ol.content); setSelectedOfferLetter(ol.uid)}}>{ol.name}</Dropdown.Item>
        )
    })

    useEffect(() => {
        loadData();
    }, [])

    function loadData(){
        new ApiRequest('hr', 'getDocumentRequestData', setIsLoading, (response) => {
            setEmployee(response.employee);
            setCompany(response.company);
            setCsa(response.csa);
            setDailyStartTime(response.employee.dailyStartTime);
            setSigners(response.signerCandidates);
            response.offerLetters.forEach(ol=>{
                const associatedDefaults = [];
                if(response.company.defaultOfferLetterForDriver == ol.uid)
                    associatedDefaults.push('Driver')
                if(response.company.defaultOfferLetterForAvpDriver == ol.uid)
                    associatedDefaults.push('AVP Driver')
                if(response.company.defaultOfferLetterForJumper == ol.uid)
                    associatedDefaults.push('Jumper')
                if(response.company.defaultOfferLetterForBc == ol.uid)
                    associatedDefaults.push('BC')
                if(response.company.defaultOfferLetterForAdmin == ol.uid)
                    associatedDefaults.push('Admin')
                ol.associatedDefaults = associatedDefaults;
            })
            setOfferLetters(response.offerLetters);
            setStartDate(response.employee.startDate ? response.employee.startDate : '');
            if(response.company[defaultOfferLetterTitles[response.employee.pay.title]]) {
                setOfferLetter(response.offerLetters.find(ol=>ol.uid == response.company[defaultOfferLetterTitles[response.employee.pay.title]]).content) 
                setSelectedOfferLetter(response.offerLetters.find(ol=>ol.uid == response.company[defaultOfferLetterTitles[response.employee.pay.title]]).uid)
            }
            if (defaultAllDocumentsOn) {
                setSelectedTaxDocument(stateTaxDocuments[response.csa?.address.administrativeArea] ?? undefined);
            }
        }).withData({companyUserIdentifier: companyUserIdentifier}).withNoAlertOnSuccess().send()
    }

    function handleSubmit(){
        const documents = {};
        selectedDocuments.forEach((type) => {
            const document = {title: `${employee.firstName} ${employee.lastName} ${OnboardingDocumentsShort[type]}`};
            if (type == 'w4') {
                document.startDate = startDate;
            }
            documents[type] = document;
        })
        if (selectedTaxDocument) {
            documents[selectedTaxDocument] = {title: `${employee.firstName} ${employee.lastName} ${TaxDocumentsShort[selectedTaxDocument]}`};
        }
        if (selectedDirectDepositDocument) {
            documents[selectedDirectDepositDocument] = {title: `${employee.firstName} ${employee.lastName} ${DirectDepositDocuments[selectedDirectDepositDocument]}`};
        }
        if (includeOfferLetter) {
            const ao = signers.find(s => s.uid == selectedAo);
            documents.offerLetter = {
                title: `${employee.firstName} ${employee.lastName} Offer Letter`,
                createdDate: moment().format('YYYY-MM-DD'),
                content: offerLetter,
                companyName: company.companyName,
                employeeType: employee.pay.employeeType,
                startDate: startDate,
                supervisorName: supervisorName,
                terminalAddress: csa.address,
                startTime: startTime,
                payType: employee.pay.payType,
                payRate: validateDecimalFixed(employee.pay.payRate, 2),
                stopBonusWeekdayThreshold: validateInteger(employee.pay.stopBonusWeekdayThreshold),
                stopBonusWeekendThreshold: validateInteger(employee.pay.stopBonusWeekendThreshold),
                stopBonusWeekdayAmount: validateDecimalFixed(employee.pay.stopBonusAmount, 2),
                stopBonusWeekendAmount: validateDecimalFixed(employee.pay.stopBonusWeekendAmount, 2),
                expectedAvailability: availabilityToBinaryString(employee.availability),
                dailyStartTime: dailyStartTime,
                benefits: company.benefits,
                aoName: `${ao.firstName} ${ao.lastName}`,
                aoPhoneNumber: ao.phoneNumber,
                aoEmail: ao.email
            };
        }

        new ApiRequest('hr', 'createDocumentRequest', setIsSubmitting, () => hideModal()).withData({
            companyUserIdentifier: companyUserIdentifier,
            documents: documents,
            employerEmail:  employerSigner ?? '',
            includeEContacts: includeEContacts,
            startDate:  startDate ? startDate : null
        }).send()
    }

    const offerLetterAos = signers.filter(s => s.title === 'AO');
    const validationGroup = new ValidationGroup();
    const totalDocumentsSelected = selectedDocuments.length + (selectedTaxDocument ? 1 : 0) + (includeEContacts ? 1 : 0) + (includeOfferLetter ? 1 : 0) + (selectedDirectDepositDocument ? 1 : 0);
    const requiresEmployerSigner = selectedDocuments.includes('i9') || selectedDocuments.includes('f8850') || ['a4', 'l4', 'miw4', 'it2104', 'rev419'].includes(selectedTaxDocument);
    const requiresStartDate = selectedDocuments.includes('w4') || selectedDocuments.includes('i9') || includeOfferLetter;
    const offerLetterRadioValues = [-1, ...offerLetters.map(ol => ol.uid)];
    const offerLetterRadioLabels = ['TTA Default', ...offerLetters.map(ol => `${ol.name}${ol.associatedDefaults.length ? ` (${ol.associatedDefaults.join(', ')})` : ''}`)];
    
    if (selectedOfferLetter == null) {
        offerLetterRadioValues.push(null);
        offerLetterRadioLabels.push('Custom');
    }

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Send Employee Document Request</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12}}>
                { isLoading ? <PageSpinner/> :
                    <>
                        <div style={{border: '1px solid lightgray', padding: 12, borderRadius: 6}}>
                            <h5 style={{textAlign: 'center'}}>Onboarding Documents to Include</h5>
                            <Container fluid style={{padding: 0}}>
                                <Row>
                                    <Col xl={6} style={{display: 'flex', flexDirection: 'column', gap: 8, marginBottom: 8}}>
                                        <CustomCheck inline title='Documents' selection={selectedDocuments} setSelection={setSelectedDocuments} optionNames={Object.values(OnboardingDocuments)} optionValues={Object.keys(OnboardingDocuments)}/>                                    
                                        <SwitchControl title={'Include Direct Deposit Form'} value={selectedDirectDepositDocument !== null} setValue={(value) => {setSelectedDirectDepositDocument(value ? 'dd' : null)}}/>
                                        { selectedDirectDepositDocument && 
                                            <RadioControl title='Selected Direct Deposit Form' inline selection={selectedDirectDepositDocument} setSelection={setSelectedDirectDepositDocument} optionNames={Object.values(DirectDepositDocuments)} optionValues={Object.keys(DirectDepositDocuments)}/>
                                        }
                                        <SwitchControl title={'Request Emergency Contacts'} value={includeEContacts} setValue={setIncludeEContacts}/>
                                    </Col>
                                    <Col xl={6} style={{display: 'flex', flexDirection: 'column', gap: 8, marginBottom: 8}}>
                                        <SwitchControl title={'Include State Tax Document'} value={selectedTaxDocument !== undefined} setValue={(value) => {setSelectedTaxDocument(value ? stateTaxDocuments[csa?.address.administrativeArea] ?? 'it4' : undefined)}}/>
                                        { selectedTaxDocument && 
                                            <RadioControl inline title={'Selected State Tax Document'} selection={selectedTaxDocument} setSelection={setSelectedTaxDocument} optionNames={Object.values(taxDocumentDictionary)} optionValues={Object.keys(taxDocumentDictionary)}/>
                                        }
                                        <SwitchControl title={'Include Offer Letter'} value={includeOfferLetter} setValue={setIncludeOfferLetter}/>
                                        { includeOfferLetter && 
                                            <>
                                                <RadioControl 
                                                    inline 
                                                    title={'Selected Offer Letter'} 
                                                    selection={selectedOfferLetter} 
                                                    setSelection={(value)=>{setOfferLetter(offerLetters.find(ol=>ol.uid == value)?.content ?? defaultOfferLetter); setSelectedOfferLetter(value)}} 
                                                    optionNames={offerLetterRadioLabels} 
                                                    optionValues={offerLetterRadioValues}
                                                />
                                                <Button variant='outline-primary' onClick={() => {setShowOfferLetter(true)}}>
                                                    <span>Edit Offer Letter</span>
                                                </Button>
                                            </>
                                        }
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        { (requiresEmployerSigner || requiresStartDate || includeOfferLetter) && 
                            <div style={{border: '1px solid lightgray', padding: 12, borderRadius: 6}}>
                                <h5 style={{textAlign: 'center'}}>Required Information</h5>
                                <Container fluid style={{padding: 0}}>
                                    <Row>
                                        { requiresEmployerSigner && 
                                            <RadioControl xl={12} inline validation={() => validateExistence(employerSigner)} title={'Signer on behalf of Employer'} selection={employerSigner} setSelection={setEmployerSigner} optionNames={signers.map(c => `${c.firstName} ${c.lastName} (${c.title})`)} optionValues={signers.map(c => c.email)} validator={validationGroup.createValidator(() => validateExistence(employerSigner))}/>
                                        }
                                        
                                        { requiresStartDate && 
                                            <CustomControl xl={3} lg={6} title='Employment Start Date' value={startDate} onChange={(event) => {setStartDate(event.target.value)}} type='date' validator={validationGroup.createValidator(() => validateExistence(startDate))}/>
                                        }
                                        { includeOfferLetter && 
                                            <>
                                                <CustomControl xl={3} lg={6} title='Name of Immediate Supervisor' value={supervisorName} onChange={(event) => {setSupervisorName(event.target.value)}} validator={validationGroup.createValidator(() => validateExistence(supervisorName))}/>
                                                <CustomControl xl={3} lg={6} title='Start Time on First Day' value={startTime} onChange={(event) => {setStartTime(event.target.value)}} type='time' validator={validationGroup.createValidator(() => validateExistence(startTime))}/>
                                                <CustomControl xl={3} lg={6} title='Daily Start Time' value={dailyStartTime} onChange={(event) => {setDailyStartTime(event.target.value)}} type='time' validator={validationGroup.createValidator(() => validateExistence(dailyStartTime))}/>
                                                <RadioControl xl={12} title='AO to Appear on Offer Letter' selection={selectedAo} setSelection={setSelectedAo} optionValues={offerLetterAos.map(ao => ao.uid)} optionNames={offerLetterAos.map(ao => `${ao.firstName} ${ao.lastName}`)} inline validator={validationGroup.createValidator(() => validateExistence(selectedAo))}/>
                                            </>
                                        }
                                    </Row>
                                </Container>
                            </div>
                        }
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
                <span style={{color: totalDocumentsSelected ? 'green' : 'red'}}>{`${totalDocumentsSelected} document${totalDocumentsSelected != 1 ? 's' : ''} selected`}</span>
                <CustomButton label={`Submit Document Request`} isLoading={isSubmitting} onClick={handleSubmit} disabled={!validationGroup.isValid() || totalDocumentsSelected == 0 || (includeOfferLetter && !offerLetter)}/>
            </Modal.Footer>
            <Modal show={showOfferLetter} onHide={() => {setShowOfferLetter(false)}} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>Offer Letter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Dropdown style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Dropdown.Toggle variant='outline-primary'>Import Template</Dropdown.Toggle>
                        <Dropdown.Menu>
                            {offerLetterElements}
                        </Dropdown.Menu>
                    </Dropdown>
                    <h5 style={{textAlign: 'center', color: 'gray', fontStyle: 'italic', margin: 0}}>Any modifications will be reflected in the offer letter sent to the employee</h5>
                    <OfferLetterEditor value={offerLetter} setValue={setOfferLetter} setSelectedOfferLetter={setSelectedOfferLetter} offerLetterData={{
                        companyName: company?.companyName,
                        employeeName: `${employee?.firstName} ${employee?.lastName}`,
                        employeeType: employee?.pay.employeeType,
                        startDate: startDate,
                        supervisorName: supervisorName,
                        terminalAddress: csa?.address,
                        startTime: startTime,
                        payType: employee?.pay.payType,
                        payRate: validateDecimalFixed(employee?.pay.payRate, 2),
                        stopBonusWeekdayThreshold: validateInteger(employee?.pay.stopBonusWeekdayThreshold),
                        stopBonusWeekendThreshold: validateInteger(employee?.pay.stopBonusWeekendThreshold),
                        stopBonusWeekdayAmount: validateDecimalFixed(employee?.pay.stopBonusAmount, 2),
                        stopBonusWeekendAmount: validateDecimalFixed(employee?.pay.stopBonusWeekendAmount, 2),
                        expectedAvailability: availabilityToBinaryString(employee?.availability ?? ''),
                        dailyStartTime: dailyStartTime,
                        benefits: company?.benefits,
                        aoName: `${signers.find(s => s.uid == selectedAo)?.firstName ?? ''} ${signers.find(s => s.uid == selectedAo)?.lastName ?? ''}`,
                        aoPhoneNumber: signers.find(s => s.uid == selectedAo)?.phoneNumber ?? '',
                        aoEmail: signers.find(s => s.uid == selectedAo)?.email ?? ''
                    }}/>
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='outline-primary' onClick={() => {setShowOfferLetter(false)}}>Done</Button>
                </Modal.Footer>
            </Modal>
            
        </>
    )
}